







































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {VForm} from "@/global";
import InfoCard from "./components/info-card.vue";

import {isEqual} from "lodash";
import {BButton, BCard, BCardTitle, BNavItem, BTab, BTabs} from "bootstrap-vue";
import ScopeSelector from "@/apps/maintenance-api/pages/components/ScopeSelector/index.vue";

@Component({
  components: {
    InfoCard,
    BCard,
    BCardTitle,
    BButton,
    BTabs,
    BTab,
    BNavItem,
    ScopeSelector
  }
})
export default class Role extends Vue {
  $refs!: {
    form: VForm
  }

  item: MaintenanceAPI.Roles.Item = null;

  platforms: MaintenanceAPI.Platforms.Item[] = [];

  async mounted() {
    this.platforms = await this.$api.maintenance.platforms.GetList();
  }

  @Watch("id", {immediate: true})
  async watchId() {
    if (this.$route.params.edit != undefined)
      this.editing = true;

    await this.getItem();
  }

  get id() {
    return this.$route.params.identifier;
  }

  toggleEditing() {
    this.editing = !this.editing;
  }

  editing = false;

  async getItem() {
    const item = await this.$api.maintenance.roles.Get(this.id);
    this.$set(this, "item", item);
  }

  async saveChanges() {
    if (!await this.$refs.form.validate()) return;

    if (this.editing)
      return await this.update();
  }

  async update() {
    const res = await this.$api.maintenance.roles.Update(this.item.id, {...this.editingItem});
    this.editing = false;
    this.$set(this, "item", res);
  }

  // Editing Functions
  get hasBeenEdited() {
    return !isEqual(this.item, this.editingItem)
  }

  editingItem?: MaintenanceAPI.Roles.Item = null;

  @Watch('item', {immediate: true, deep: true})
  watchItem() {
    this.$set(this, "editingItem", JSON.parse(JSON.stringify({...this.item})));
  }

  scopeSelect({path, remove}) {
    if(remove){
      this.editingItem.scopes.splice(this.item.scopes.findIndex(x=>x===path), 1);
      return;
    }
    this.editingItem.scopes.push(path);
  }
}
